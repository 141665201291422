<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-15 14:16:50
 * @LastEditTime: 2022-09-05 14:34:50
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\target.vue
-->
<template>
  <div class="target">
    <div class="detail">
      <el-descriptions>
        <el-descriptions-item label="类型">
          {{ detailist.type == 1 ? '通用优惠券' : '专属优惠券' }}
        </el-descriptions-item>
        <el-descriptions-item label="优惠券名称">
          {{ detailist.name }}
        </el-descriptions-item>
        <el-descriptions-item label="优惠方式">
          {{
            detailist.pre_way == 1
              ? `满减${detailist.cou_price}元`
              : `打${detailist.discount}折`
          }}
        </el-descriptions-item>
        <el-descriptions-item label="使用门槛">
          {{
            detailist.threshold == 0
              ? '无门槛'
              : `满${detailist.threshold}元可用`
          }}
        </el-descriptions-item>
        <el-descriptions-item label="有效期">
          {{ detailist.validity }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="contain">
      <div class="title">领用规则</div>
      <div class="info">
        定向发放可通过多维度条件筛选特定用户，并在用户进入网校时自动发放优惠券，有效提高网校的付费转化与复购率。
      </div>
      <el-form
        ref="form"
        :model="form"
        label-width="160px"
        label-position="left"
        class="mt20"
      >
        <el-form-item label="发放对象" :required="true">
          <el-radio-group
            v-model="form.type"
            style="margin-top:18px"
            @change="mainRdiaoChange"
          >
            <p class="mb30">
              <el-radio label="1">
                付费用户
                <div class="radio_contain1" v-if="form.type == 1">
                  <div class="info">
                    用户在店铺内产生过支付行为（含支付金额为0）即为付费用户，可指定付费时间/商品
                  </div>
                  <div class="radio_group">
                    <div class="name">对象筛选：</div>
                    <div>
                      <el-radio-group
                        v-model="form.grant_type"
                        @change="granttypeChange"
                      >
                        <el-radio label="1">指定付费时间</el-radio>
                        <el-radio label="2">指定付费商品</el-radio>
                      </el-radio-group>
                      <div class="fortime" v-if="form.grant_type == 1">
                        <el-date-picker
                          size="medium"
                          :picker-options="pickerOptions"
                          @change="customtimepick"
                          v-model="form.value1"
                          value-format="timestamp"
                          type="daterange"
                          :clearable="false"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        ></el-date-picker>
                        <div class="sp1">时间段内的所有付费用户</div>
                      </div>

                      <div class="forgood" v-else>
                        <div class="flex-center mt20 mb20">
                          <el-button
                            type="primary"
                            @click="networkschoolSelectStatus = true"
                            class="mr10"
                            size="medium"
                          >
                            + 添加付费商品
                          </el-button>
                          <div class="info2">{{ goodsList.length }}/100</div>
                        </div>
                        <div class="good_contain">
                          <div
                            class="item"
                            v-for="(i, index) in goodsList.slice(
                              (currentPage1 - 1) * 5,
                              (currentPage1 - 1) * 5 + 5
                            )"
                            :key="i.id"
                          >
                            <div class="top">
                              <div class="tu">
                                <img
                                  :src="i.cover_img || i.photo || i.img_url"
                                  alt=""
                                />
                              </div>
                              <div class="right">
                                <div
                                  class="flex-center"
                                  style="justify-content:space-between"
                                >
                                  <div class="itemtitle">{{ i.name }}</div>
                                  <el-button
                                    type="text"
                                    @click="del(index)"
                                    class="fs14  "
                                    size="mini"
                                  >
                                    移除
                                  </el-button>
                                </div>
                                <div class="msg">
                                  <div class="flex-center">
                                    <template v-if="i.price">
                                      <div class="money">￥{{ i.price }}</div>
                                      <div
                                        class="cross_money"
                                        v-if="
                                          (i.crossed_price &&
                                            i.crossed_price > 0) ||
                                            (i.costprice && i.costprice > 0) ||
                                            (i.cost_price && i.cost_price > 0)
                                        "
                                      >
                                        ￥
                                        {{
                                          i.crossed_price ||
                                            i.costprice ||
                                            i.cost_price
                                        }}
                                      </div>
                                    </template>
                                    <template v-if="i.member_id">
                                      <div class="money" style="color:#333333">
                                        课程数:{{ i.num }}
                                      </div>
                                    </template>
                                    <div class="course_type">
                                      {{ i.good_type | getGoodtext }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="setting">
                              <el-radio-group
                                v-model="i.timetype"
                                @change="$forceUpdate(), grant()"
                              >
                                <el-radio label="1">
                                  全部时间段(截止昨日)
                                </el-radio>
                                <el-radio label="2">指定时间段</el-radio>
                              </el-radio-group>
                              <el-date-picker
                                class="ml10"
                                :disabled="i.timetype == 1"
                                size="medium"
                                :picker-options="pickerOptions"
                                @blur="customtimepick2($event, index)"
                                :clearable="false"
                                v-model="i.timeValue"
                                type="daterange"
                                value-format="timestamp"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              ></el-date-picker>
                            </div>
                          </div>
                        </div>
                        <el-pagination
                          v-if="goodsList.length > 0"
                          :pager-count="5"
                          :current-page.sync="currentPage1"
                          :page-size="5"
                          layout="prev, pager, next, jumper"
                          :total="goodsList.length"
                        ></el-pagination>
                      </div>
                      <div class="flex-center">
                        <div
                          v-if="
                            form.grant_type == 1 ||
                              (goodsList.length > 0 && form.grant_type == 2)
                          "
                        >
                          预计发放 {{ getUserNum }} 人， {{ getUserNum }} 张
                          <helpIcon
                            style="margin-left: 6px"
                            content="该人数未去重，实际发放人数可能存在偏差"
                          ></helpIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-radio>
            </p>
            <p class="mb30">
              <el-radio label="2">
                用户群体
                <div class="radio_contain1" v-if="form.type == 2">
                  <div class="info">
                    给指定人群/标签下的所有用户，批量发放优惠券
                  </div>
                  <div class="radio_group">
                    <div class="name">对象筛选：</div>
                    <div>
                      <el-radio-group
                        v-model="form.grant_type"
                        @change="granttypeChange"
                      >
                        <el-radio label="3">指定用户分组</el-radio>
                        <el-radio label="4">指定用户标签</el-radio>
                      </el-radio-group>
                      <div>
                        <div class="flex-center mt20 mb20">
                          <el-button
                            type="primary"
                            @click="
                              form.grant_type == 3
                                ? (groupstatus = true)
                                : (tagstatus = true)
                            "
                            class="mr10"
                            size="medium"
                          >
                            {{
                              form.grant_type == 3
                                ? ' + 添加用户分组'
                                : '+ 添加用户标签'
                            }}
                          </el-button>
                          <div class="info2">
                            {{
                              form.grant_type == 3
                                ? hadselectList.length
                                : tagHadselectList.length
                            }}/100
                          </div>
                        </div>
                        <el-table
                          v-if="
                            (form.grant_type == 3 &&
                              hadselectList.length > 0) ||
                              (form.grant_type == 4 &&
                                tagHadselectList.length > 0)
                          "
                          :header-cell-style="{
                            background: 'rgba(245,245,245,1)',
                            color: '#333333',
                          }"
                          :data="
                            form.grant_type == 3
                              ? hadselectList.slice(
                                  (currentPage2 - 1) * 5,
                                  (currentPage2 - 1) * 5 + 5
                                )
                              : tagHadselectList.slice(
                                  (currentPage2 - 1) * 5,
                                  (currentPage2 - 1) * 5 + 5
                                )
                          "
                          style="width: 590px;"
                        >
                          <el-table-column
                            :label="
                              form.grant_type == 3 ? '用户分组' : '用户标签'
                            "
                            width="180"
                            :prop="form.grant_type == 3 ? 'gname' : 'tag_name'"
                          ></el-table-column>
                          <el-table-column
                            prop="number"
                            label="包含人数(人)"
                            width="180"
                          ></el-table-column>
                          <el-table-column prop="address" label="操作">
                            <template slot-scope="{ row }">
                              <el-button
                                type="text"
                                @click="
                                  form.grant_type == 3
                                    ? delgroup(row)
                                    : delTag(row)
                                "
                              >
                                移除
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                        <el-pagination
                          v-if="
                            (form.grant_type == 3 &&
                              hadselectList.length > 0) ||
                              (form.grant_type == 4 &&
                                tagHadselectList.length > 0)
                          "
                          :pager-count="5"
                          class="mt20"
                          :current-page.sync="currentPage2"
                          :page-size="5"
                          layout="prev, pager, next, jumper"
                          :total="
                            form.grant_type == 3
                              ? hadselectList.length
                              : tagHadselectList.length
                          "
                        ></el-pagination>
                        <div class="flex-center">
                          <div
                            class="mt20"
                            v-if="
                              (form.grant_type == 3 &&
                                hadselectList.length > 0) ||
                                (form.grant_type == 4 &&
                                  tagHadselectList.length > 0)
                            "
                          >
                            预计发放
                            {{
                              form.grant_type == 3
                                ? hadselectListNum
                                : tagHadselectListNum
                            }}
                            人，
                            {{
                              form.grant_type == 3
                                ? hadselectListNum
                                : tagHadselectListNum
                            }}
                            张
                            <helpIcon
                              style="margin-left: 6px"
                              content="该人数未去重，实际发放人数可能存在偏差"
                            ></helpIcon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-radio>
            </p>
            <p class="mb30">
              <el-radio label="3">
                指定用户
                <div class="radio_contain1" v-if="form.type == 3">
                  <div class="info">
                    选择指定用户发放，同时选择多个用户时，可分别发放不同数量的优惠券
                  </div>
                  <div class="flex-center mt20 mb20">
                    <el-button
                      type="primary"
                      @click="statusAddMember = true"
                      class="mr10"
                      size="medium"
                    >
                      + 添加用户
                    </el-button>
                    <div class="info2">{{ mumberlist.length }}/100</div>
                  </div>

                  <el-table
                    v-if="mumberlist.length > 0"
                    :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                    :data="
                      mumberlist.slice(
                        (currentPage3 - 1) * 5,
                        (currentPage3 - 1) * 5 + 5
                      )
                    "
                    style="width: 640px;"
                  >
                    <el-table-column label="指定用户" width="180">
                      <template slot-scope="{ row }">
                        <div class="user_contain">
                          <div class="tu"><img :src="row.uphoto" alt="" /></div>
                          {{ row.uname }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="真实姓名" width="180">
                      <template slot-scope="{ row }">
                        {{ row.student_remarks ? row.student_remarks : '--' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="number" label="发行数量" width="180">
                      <template slot-scope="{ row }">
                        <el-input-number
                          style="width:110px"
                          v-model="row.num"
                          controls-position="right"
                          :step="1"
                          :min="1"
                          :max="100"
                          size="medium"
                        ></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作">
                      <template slot-scope="{ $index }">
                        <el-button type="text" @click="delMumber($index)">
                          移除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination
                    v-if="mumberlist.length > 0"
                    :pager-count="5"
                    class="mt20"
                    :current-page.sync="currentPage3"
                    :page-size="5"
                    layout="prev, pager, next, jumper"
                    :total="mumberlist.length"
                  ></el-pagination>
                  <div class="flex-center">
                    <div class="mt20" v-if="mumberlist.length > 0">
                      预计发放 {{ mumberlist.length }} 人，
                      {{ mumberlistNum }} 张
                      <helpIcon
                        style="margin-left: 6px"
                        content="该人数未去重，实际发放人数可能存在偏差"
                      ></helpIcon>
                    </div>
                  </div>
                </div>
              </el-radio>
            </p>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button size="medium" style="width:100px" @click="cancel">
          取消
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="submit"
          style="width:100px"
        >
          发放
        </el-button>
      </div>
    </div>

    <!-- 分组选择 -->
    <group-list
      v-if="groupstatus"
      :limit="tagGroupLint"
      @selectionChange="selectionChange"
      :hadselectList="hadselectList"
      :dialogstatus.sync="groupstatus"
    ></group-list>

    <!-- 选择标签 -->
    <tag-list
      v-if="tagstatus"
      :limit="tagGroupLint"
      @selectionChange="tagSelectionChange"
      :hadselectList="tagHadselectList"
      :dialogstatus.sync="tagstatus"
    ></tag-list>

    <!-- 选择商品 -->
    <networkschoolSelect
      @selectChangenetworkschool="networkschoolSelectStatus = false"
      :goodList="goodsList"
      :goods_class="1"
      @singleselectcontentval="singleselectcontentval"
      title="添加商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="14"
      :goodlistType="true"
    ></networkschoolSelect>

    <!-- 添加用户 -->
    <newaddmember
      :slimt="100"
      v-if="statusAddMember"
      :type="2"
      :ids="numberIDs"
      :dialogstatus.sync="statusAddMember"
      @complete="mumbercomplete"
    ></newaddmember>
  </div>
</template>

<script>
import newaddmember from '@cm/base/newaddmember'
import groupList from './components/groupList'
import tagList from './components/tagList'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
export default {
  name: 'target',

  components: {
    networkschoolSelect,
    groupList,
    tagList,
    newaddmember,
  },

  computed: {
    mumberlistNum() {
      return this.mumberlist.reduce(
        (total, item) => Number(total) + Number(item.num),
        0
      )
    },

    hadselectListNum() {
      return this.hadselectList.reduce(
        (total, item) => Number(total) + Number(item.number),
        0
      )
    },

    tagHadselectListNum() {
      return this.tagHadselectList.reduce(
        (total, item) => Number(total) + Number(item.number),
        0
      )
    },

    numberIDs() {
      return this.mumberlist.map(item => item.uid)
    },
    coupon_id() {
      return this.$route.params.id
    },
  },

  filters: {
    getGoodtext(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '电子资料'
          break
        case 7:
          text = '会员'
          break
        case 8:
          text = '知识商品'
          break
        case 9:
          text = '图文'
          break
      }

      return text
    },
    getgoodType(val) {
      let endVal = null
      if (val.course_id) {
        switch (Number(val.course_type)) {
          case 1:
            endVal = 1
            break
          case 2:
            endVal = 2
            break
          case 3:
            endVal = 3
            break
          default:
            endVal = 4
            break
        }
      } else if (val.open_class_id) {
        endVal = 5
      } else if (val.ed_id) {
        endVal = 6
      } else if (val.user_id) {
        endVal = 7
      } else if (val.live_goods_id) {
        endVal = 8
      } else if (val.graphic_id) {
        endVal = 9
      }
      return endVal
    },
  },

  data() {
    return {
      mumberlist: [],

      statusAddMember: false,

      currentPage2: 1,

      tagHadselectList: [],

      hadselectList: [],

      tagGroupLint: 100,

      tagstatus: false,

      groupstatus: false,

      currentPage1: 1,

      currentPage3: 1,

      networkschoolSelectStatus: false,

      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() > Date.now() - 86400000 //设置选择今天之前的日期（不能选择当天时间）
        },
      },

      getUserNum: 0,

      goodsList: [],

      form: {
        userList: '',
        goodsList: [],
        type: '1',
        grant_type: '1',
        value1: '',
        end_time: '',
        start_time: '',
      },

      detailist: {},
    }
  },

  created() {
    this.setCoupon()
  },

  methods: {
    //取消
    cancel() {
      this.$router.push({ path: '/coupon' })
    },

    // 发放
    async submit() {
      if (this.form.type != 3) {
        if (this.form.grant_type == 1 && !this.form.value1) {
          this.$root.prompt({ msg: '请选择时间' })
          return
        }
        if (this.form.grant_type == 2) {
          if (this.goodsList.length == 0) {
            this.$root.prompt({ msg: '请选择商品' })
            return
          }

          const notime = this.goodsList.filter(
            item => item.timetype == 2 && (!item.start_time || !item.end_time)
          )
          if (notime.length > 0) {
            this.$root.prompt({ msg: '请选择自定时间段' })
            return
          }
        }

        if (this.form.grant_type == 3 && this.hadselectList.length == 0) {
          this.$root.prompt({ msg: '请添加用户分组' })
          return
        }
        if (this.form.grant_type == 4 && this.tagHadselectList.length == 0) {
          this.$root.prompt({ msg: '请添加用户标签' })
          return
        }
      } else {
        this.form.grant_type = '5'
        if (this.mumberlist.length == 0) {
          this.$root.prompt({ msg: '请添加用户' })
          return
        }
      }

      this.form = _.assign({}, this.form, {
        coupon_id: this.coupon_id,
        goodsList: this.goodsList,
        identity_ids:
          this.form.grant_type == 3
            ? this.hadselectList.map(item => item.gid)
            : this.form.grant_type == 4
            ? this.tagHadselectList.map(item => item.tag_id)
            : [],
        userList: this.mumberlist,
      })

      await this.$http({
        url: '/coupon/grant',
        data: this.form,
      })
      this.cancel()
    },

    // 移除学员
    delMumber(index) {
      this.mumberlist.splice(index, 1)
      this.grant()
    },

    // 添加学员
    mumbercomplete(val) {
      this.mumberlist.push(
        ...val.map(item => {
          return {
            ...item,
            num: 1,
          }
        })
      )
      // console.log(this.mumberlist)
      // this.grant()
    },

    // 标签选择的内容
    tagSelectionChange(val) {
      this.tagHadselectList = val
      this.tagstatus = false
      // this.grant()
    },

    // 删除标签
    delTag(val) {
      const index = this.tagHadselectList.findIndex(
        item => item.tag_id == val.tag_id
      )
      this.tagHadselectList.splice(index, 1)
      this.grant()
    },

    // 删除分组
    delgroup(val) {
      const index = this.hadselectList.findIndex(item => item.gid == val.gid)
      this.hadselectList.splice(index, 1)
      this.grant()
    },

    // 分组选中的数
    selectionChange(val) {
      this.groupstatus = false
      this.hadselectList = val

      // this.grant()
    },

    // 第一层radio的切换
    mainRdiaoChange(val) {
      this.form = _.assign({}, this.form, {
        grant_type: val == 1 ? '1' : val == 2 ? '3' : '5',
      })
      this.grant()
    },

    // 删除商品
    del(index) {
      this.goodsList.splice(index, 1)
      this.grant()
    },

    // 选择的商品
    singleselectcontentval(val) {
      val.map(item => {
        item.id = Math.random()
        item.good_type = this.$options.filters.getgoodType(item)
        item.start_time = ''
        item.end_time = ''
        item.timeValue = []
        item.timetype = '1'
        return item
      })

      this.goodsList.push(...val)
      this.grant()
    },

    granttypeChange() {
      this.grant()
    },

    customtimepick2(e, index) {
      let val = this.goodsList[index].timeValue || [0, 0]
      this.goodsList[index].start_time = val[0] / 1000
      this.goodsList[index].end_time = val[1] / 1000
      if (val.length > 0) {
        this.grant()
      }
      this.$forceUpdate()
    },

    // 时间
    customtimepick(val) {
      val = val || [0, 0]
      this.form.start_time = val[0] / 1000
      this.form.end_time = val[1] / 1000
      if (val.length > 0) {
        this.grant()
      }
    },

    // 获取预计人数
    async grant() {
      this.form = _.assign({}, this.form, {
        identity_ids:
          this.form.grant_type == 3
            ? this.hadselectList.map(item => item.gid)
            : this.form.grant_type == 4
            ? this.tagHadselectList.map(item => item.tag_id)
            : [],
        userList: this.mumberlist,
        goodsList: this.goodsList,
      })
      const { data } = await this.$http({
        url: '/coupon/getUserNum',
        data: this.form,
      })
      this.getUserNum = data
    },

    async setCoupon() {
      const { data } = await this.$http({
        url: `/coupon/couponHead`,
        data: { coupon_id: this.coupon_id },
      })
      this.detailist = data
    },
  },
}
</script>

<style lang="scss" scoped>
.target {
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #333333;
  }
  .btns {
    text-align: center;
  }
  ::v-deep .el-input-number__decrease {
    background-color: #fff;
  }
  ::v-deep .el-input-number__increase {
    background-color: #fff;
  }
  .user_contain {
    display: flex;
    align-items: center;
    .tu {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .sp1 {
    font-size: 14px;
    color: #333333;
    margin-left: 10px;
  }
  .info {
    font-size: 14px;
    color: #666666;
    margin: 10px 0 20px;
  }
  .info2 {
    font-size: 14px;
    color: #666666;
  }
  .detail {
    background-color: #fff;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
  }
  .contain {
    padding: 20px;
    background-color: #fff;
    ::v-deep .el-pagination {
      transform: scale(0.85);
      transform-origin: center;
      position: absolute;
      right: 0;
    }
    ::v-deep .el-pager li {
      background: #f4f4f5;
      margin: 0 4px;
      padding: 0;
      font-size: 12px;
    }
    ::v-deep .el-pagination button {
      background: #f4f4f5 !important;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    .info {
      font-size: 14px;
      color: #666666;
      margin-top: 12px;
    }
  }
  .radio_contain1 {
    margin: 10px 0 0 30px;
    color: #333333;
    .radio_group {
      display: flex;
      .fortime {
        margin: 10px 0;
        display: flex;
        align-items: center;
      }

      .forgood {
        .good_contain {
          //   max-height: 600px;
          //   overflow: auto;
          //   @extend %scroll;
          line-height: 1.5;
          .item {
            padding: 20px;
            width: 681px;
            background: #f5f5f5;
            box-sizing: border-box;
            margin-bottom: 10px;
            .top {
              display: flex;
              .tu {
                width: 66px;
                height: 46px;
                background: #d8d8d8;
                margin-right: 14px;
                flex-shrink: 0;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
              .right {
                flex: 1;
                overflow: hidden;
                .itemtitle {
                  width: 516px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 16px;
                  color: #333333;
                }
                .msg {
                  display: flex;
                  align-items: center;
                  width: 500px;
                  justify-content: space-between;

                  .money {
                    font-size: 14px;
                    color: #ff3535;
                  }
                  .cross_money {
                    font-size: 14px;
                    color: #999999;
                    margin: 0 30px 0 15px;
                    text-decoration: line-through;
                  }
                  .course_type {
                    font-size: 14px;
                    color: #333333;
                    margin-left: 30px;
                  }

                  .del {
                  }
                }
              }
            }
            .setting {
              margin-top: 10px;
            }
          }
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-right: 17px;
      }
    }
  }
}
</style>
